/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import produce from "immer";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import useApiViaje from "../../../api/operaciones/useApiViajes";
import IconLibrary from "../../../shared/iconLibrary";
import alerts from "../../../shared/alerts";
import AddendaSoriana from "../../../shared/addendas/addendaSoriana";
// import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import useSeguridad from "../../../Seguridad/useSeguridad";
import constantes from "../../../catalogos/constantes";

const Articulos = ({viaje, errors, actualizar, soloLectura = false}) => {
    const api = useApiViaje();
    const seguridad = useSeguridad();
    let permisoAjusteCambioTarifa = seguridad.tieneAcceso(constantes.opciones.permisotarifaFacturacion)
    const defaultItems = [
        {value: "CLAVE DE PRODUCTO"},
        {value: "DESCRIPCION"},
        {value: "CANTIDAD"},
        {value: "UNIDAD DE MEDIDA ID"},
        {value: "NOMBRE UNIDAD DE MEDIDA"},
        {value: "PESO EN KG"},
        {value: "MATERIAL PELIGROSO (Si o No)"},
        {value: "EMBALAJE"},
        {value: "FRACCCION ARANCELARIA"},
        {value: "PEDIMENTO"},
        {value: "TIPO MATERIA"},
        {value: "DESCRIPCION MATERIA"},
        {value: "VALOR MERCANCIA"},
        {value: "MONEDA"},
        {value: false},
        {value: false}
    ];
    const todoItems = viaje.articulos?.map((todo) => [
        {value: todo.claveSat},
        {value: todo.descripcion},
        {value: todo.cantidad},
        {value: todo.unidadConClave?.id},
        {value: todo.unidadConClave?.nombre},
        {value: todo.peso},
        {value: todo.materialPeligroso},
        {value: todo.embalaje},
        {value: todo.fraccionArancelaria},
        {value: todo.pedimento},
        {value: todo.tipoMateria},
        {value: todo.descripcionMateria},
        {value: todo.valorMercancia},
        {value: todo.moneda},
        {value: todo.enSAT},
        {value: todo.fraccionValida},
    ]);
    const clienteId = viaje.cliente?.id;

    const [, setMostrarExcel] = useState(true);
    const [data] = useState([todoItems[0] ?? defaultItems]);

    // const [permisoAjusteCambioTarifa, setPermisoAjusteCambioTarifa] = useState(false);
    // const seguridad = useSeguridad();
    useEffect(() => {
        // const permisoCambioTarifa = seguridad.tieneAcceso(constantes.opciones.permisoCambioTarifa);
        // setPermisoAjusteCambioTarifa(!permisoCambioTarifa);
    }, []);

    useEffect(() => {
        onExcelInit();
        let determina = viaje.articulos === 0 ? true : false; //determina = determina == fviaje.timbrado;
        setMostrarExcel(determina);
    }, [data]);

    function onExcelInit() {
        var items = [];
        var aTimbrar = [];
        // build previous entity
        var previos = data.map((todo) => [
            {
                claveSat: todo[0],
                descripcion: todo[1],
                cantidad: todo[2],
                id: todo[3],
                nombre: todo[4],
                peso: todo[5],
                materialPeligroso: todo[6],
                embalaje: todo[7],
                fraccionArancelaria: todo[8],
                pedimento: todo[9],
                tipoMateria: todo[10],
                descripcionMateria: todo[11],
                valorMercancia: todo[12],
                moneda: todo[13],
                enSAT: todo[14],
                fraccionValida: todo[15]
            },
        ]);
        // get data from rows
        previos.forEach((element) => element.forEach((mini) => items.push(mini)));
        // ignore undefined
        var filtered = items.filter(function (x) {
            return (
                x.peso !== undefined &&
                x.peso["value"] !== "PESO EN KG" &&
                x.claveSat !== undefined &&
                x.claveSat["value"] !== "CLAVE DE PRODUCTO" &&
                x.id !== undefined &&
                x.id["value"] !== "UNIDAD DE MEDIDA ID" &&
                x.cantidad !== undefined &&
                x.cantidad["value"] !== "CANTIDAD"
            );
        });
        // get data from specific cells
        var segundos = filtered.map((todo, index) => [
            {
                claveSat: todo.claveSat["value"],
                descripcion: todo.descripcion["value"],
                cantidad: todo.cantidad["value"],
                id: todo.id["value"],
                nombre: todo.nombre["value"],
                peso: todo.peso["value"],
                materialPeligroso: todo.materialPeligroso["value"],
                embalaje: todo.embalaje["value"],
                fraccionArancelaria: todo.fraccionArancelaria["value"],
                pedimento: todo.pedimento["value"],
                tipoMateria: todo.tipoMateria["value"],
                descripcionMateria: todo.descripcionMateria["value"],
                valorMercancia: todo.valorMercancia["value"],
                moneda: todo.moneda["value"],
                enSAT: todo.enSAT["value"],
                fraccionValida: todo["value"]
            },
        ]);
        // get data filtered from cells
        segundos.forEach((element) => element.forEach((mini) => aTimbrar.push(mini)));

        actualizar(aTimbrar, "articulosExcel");
    }

    function actualizarArticulo(index, value, propertyName) {
        const articulos = produce(viaje.articulos, (draft) => {
            if (propertyName === "fraccionArancelaria") {
                draft[index][propertyName] = value.toString().replaceAll(".", "");
            } else if (propertyName === "pedimento") {
                let ped = value.toString().replaceAll(" ", "");
                if (ped.length >= 15) {
                    draft[index][propertyName] =
                        ped.substring(0, 2) +
                        "  " +
                        ped.substring(2, 4) +
                        "  " +
                        ped.substring(4, 8) +
                        "  " +
                        ped.substring(8, ped.length);
                } else {
                    draft[index][propertyName] = value;
                }
            } else {
                draft[index][propertyName] = value;
            }
        });
        actualizar(articulos, "articulos");
    }

    async function agregarArticulo() {
        let d = {};
        if (navigator.clipboard) {
            try {
                const text = await navigator.clipboard.readText();
                if (text !== "") {
                    let result = text.split("\r\n");
                    let data2 = [];
                    let dataBack = [];
                    d = produce(result, (draft) => {
                        result.forEach((e, i) => {
                            if (draft[i] !== "") {
                                let item = {};
                                let r = draft[i].split("\t");
                                item.id = 0;
                                let producto = null;
                                if(!isNaN(r[3])) {
                                    producto = {
                                        id: parseInt(r[3] ?? 0),
                                        nombre: r[4] ?? "",
                                    };
                                }

                                let clave = (r[0] ?? "").replaceAll(" ", "");
                                item.claveSat = clave.replaceAll(/[^a-zA-Z0-9 ]/g, "");
                                item.descripcion = r[1] ?? "";
                                item.cantidad = r[2] ?? 0;
                                item.id = parseInt(r[3] ?? 0);
                                item.nombre = r[4] ?? "";
                                item.peso = r[5] ?? 0;
                                item.materialPeligroso = r[6] ?? "";
                                item.unidadConClave = producto;
                                if (r[7] !== "" && r[7] !== undefined) {
                                    item.cveMaterialPeligroso = r[7];
                                    item.embalaje = r[8];
                                }

                                if (r[9] !== "" && r[9] !== undefined) {
                                    let fa = r[9].toString().replaceAll(/[^a-zA-Z0-9 ]/g, "");
                                    item.fraccionArancelaria = fa.replaceAll(" ", "");
                                }

                                if (r[10] !== "" && r[10] !== undefined) {
                                    let ped = r[10].toString().replaceAll(" ", "");
                                    if (ped.length >= 15) {
                                        item.pedimento =
                                            ped.substring(0, 2) +
                                            "  " +
                                            ped.substring(2, 4) +
                                            "  " +
                                            ped.substring(4, 8) +
                                            "  " +
                                            ped.substring(8, ped.length);
                                    } else {
                                        item.pedimento = r[10];
                                    }
                                }

                                if (r[11] !== "" && r[11] !== undefined) {
                                    item.tipoMateria = r[11].toString().replaceAll(/[^a-zA-Z0-9 ]/g, "");
                                }

                                if (r[12] !== "" && r[12] !== undefined) {
                                    item.descripcionMateria = r[12].toString().replaceAll(/[^a-zA-Z0-9 ]/g, "");
                                }

                                item.valorMercancia = r[13] ?? 0;

                                if (r[14] !== "" && r[14] !== undefined) {
                                    let moneda = r[14].toString().replaceAll(/[^a-zA-Z0-9 ]/g, "");
                                    item.moneda = moneda.replaceAll(" ", "");
                                }

                                item.enSAT = true;
                                item.fraccionValida = true;
                                data2.push(item);
                                dataBack.push(item);
                            }
                        });
                    });

                    let articulos = [];
                    let fracciones = [];
                    let fraccionValidate = [];
                    dataBack.forEach(function (a) {
                        if (articulos.indexOf(a.claveSat) === -1) {
                            articulos.push(a.claveSat);
                        }

                        if (viaje?.internacional) {
                            if (fracciones.indexOf(a.fraccionArancelaria) === -1) {
                                fracciones.push(a.fraccionArancelaria);
                            }
                        }
                    });

                    const dataValidate = await api.validarArticulos(articulos);

                    if (viaje?.internacional) {
                        const param = {ids: fracciones.toString()};
                        fraccionValidate = await api.viajeFraccionValidar(param);
                    }

                    dataBack.forEach(function (d) {
                        if (!viaje?.internacional) {
                            d.enSAT = dataValidate.some(a => a.claveSat === d.claveSat);
                        } else {
                            d.fraccionValida = fraccionValidate.some(f => f.claveSat === d.fraccionArancelaria);
                        }
                    });

                    actualizar(dataBack, "articulos");
                    actualizar(dataBack, "articulosExcel");

                    let sinExistencia = dataBack.some(d => !d.enSAT);
                    let sinFraccion = dataBack.some(d => !d.fraccionValida);
                    let msg = "";

                    if (sinExistencia)
                        msg = "Algunos articulos no estan registrados en el catalogo del SAT. ";

                    if (sinFraccion) {
                        if (viaje?.internacional)
                            msg += "Algunes fracciones arancelarias no estan registrados en el catalogo del SAT.";
                    }

                    if (msg.length > 0)
                        alerts.informacion(msg);
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            console.log("error", d);
        }
    }

    function eliminarArticulo(index) {
        const articulos = produce(viaje.articulos, (draft) => {
            draft.splice(index, 1);
        });

        actualizar(articulos, "articulos");
    }

    async function eliminarArticulos() {
        const articulos = produce(viaje.articulos, (draft) => {
            draft.length = 0;
        });
        actualizar(articulos, "articulos");
        await api.eliminarArticulos(viaje.id, articulos);
    }

    function actualizarConceptos(concepto, index) {
        const conceptos = produce(viaje.conceptos, (draft) => {
            draft[index].monto = concepto;
        });

        actualizar(conceptos, "conceptos");
    }

    return (
        <>
            <div className="form-row">
                {viaje.cliente !== null && viaje.cliente !== undefined && (
                    <FieldDropDown
                        name="direccion"
                        label="Direcciones del cliente"
                        colMd={12}
                        source="clientesDirecciones"
                        params={{id: clienteId}}
                        disabled={viaje.timbrado || soloLectura}
                        value={viaje.direccion}
                        onChange={actualizar}
                        errors={errors}
                    />
                )}

                {viaje.tieneAddenda && <AddendaSoriana entity={viaje} actualizar={actualizar}></AddendaSoriana>}
            </div>
            <div className="form-row">
                <FieldInputNumber
                    name="tarifaConvenioExpeditado"
                    value={viaje.tarifaConvenioExpeditado}
                    required
                    disabled={viaje.timbrado || soloLectura || !permisoAjusteCambioTarifa}
                    errors={errors}
                    label={`Tarifa (${viaje.moneda})`}
                    colMd={3}
                    onChange={actualizar}
                    fractionDigits={2}
                    maxLength={6}
                    
                />
                {viaje.conceptos?.map((item, index) => {
                    return (
                        <FieldInputNumber
                            key={index}
                            name="concepto"
                            value={item.monto}
                            required
                            disabled={viaje.timbrado || soloLectura}
                            errors={errors[`conceptos[${index}]`]}
                            label={`${item.concepto.nombre}`}
                            colMd={3}
                            onChange={(e) => actualizarConceptos(e, index)}
                            fractionDigits={2}
                            maxLength={6}
                        />
                    );
                })}
            </div>
            <div className="contTabla">
                <table className="table tabla" style={{width: `${viaje.internacional ? "1850px" : "1350px"}`}}>
                    <thead className="thead-light head">
                    <tr>
                        <th/>
                        <th scope="col" style={{width: "150px"}}>
                            CLAVE SAT
                        </th>
                        <th scope="col" style={{width: "300px"}}>
                            DESCRIPCION DEL PRODUCTO
                        </th>
                        <th scope="col" style={{width: "100px"}}>
                            CANTIDAD DE PIEZAS
                        </th>
                        <th scope="col" style={{width: "200px"}}>
                            UNIDAD ID
                        </th>
                        <th scope="col" style={{width: "100px"}}>
                            PESO (KG)
                        </th>
                        <th scope="col" style={{width: "100px"}}>
                            Material Peligroso
                            <br/>
                            (Si o No)
                        </th>
                        <th scope="col" style={{width: "150px"}}>
                            Clave Material Peligroso
                        </th>
                        <th scope="col" style={{width: "150px"}}>
                            EMBALAJE
                        </th>
                        {viaje.internacional && (
                            <>
                                <th/>
                                <th scope="col" style={{width: "200px"}}>
                                    FRACCION ARANCELARIA
                                </th>
                                <th scope="col" style={{width: "300px"}}>
                                    PEDIMENTO
                                </th>
                                <th scope="col" style={{width: "200px"}}>
                                    TIPO MATERIA
                                </th>
                                <th scope="col" style={{width: "200px"}}>
                                    DESCRIPCION MATERIA
                                </th>
                                <th scope="col" style={{width: "100px"}}>
                                    VALOR MERCANCIA
                                </th>
                                <th scope="col" style={{width: "100px"}}>
                                    MONEDA
                                </th>
                            </>
                        )}
                        {!viaje.timbrado &&
                            <th className="header-icons" style={{width: "50px"}}>
                                <div className="d-flex" style={{float: "right"}}>
                                    {!soloLectura && (
                                        <>
                                            {viaje.articulos?.length === 0 ?
                                                <ButtonIcon
                                                    title={"Agregar Articulo"}
                                                    className="grid-action"
                                                    iconName={"SiAddthis"}
                                                    onClick={agregarArticulo}
                                                    autoFocus
                                                />
                                                :
                                                <ButtonIcon
                                                    title={"Eliminar"}
                                                    className="grid-action"
                                                    iconName={"RiDeleteBinLine"}
                                                    onClick={eliminarArticulos}
                                                    autoFocus
                                                />
                                            }
                                        </>
                                    )}
                                </div>
                            </th>
                        }
                    </tr>
                    </thead>
                    <tbody className={"body"}>
                    {viaje.articulos?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td style={{width: "25px"}}>
                                    {item.enSAT ? (
                                        <div
                                            className="text-success"
                                            title="Ok"
                                        >
                                            <IconLibrary iconName="FaCheck"/>
                                        </div>
                                    ) : (
                                        <div
                                            className="text-danger"
                                            title="Articulo no existe en el catalogo del SAT"
                                        >
                                            <IconLibrary iconName="RiErrorWarningLine"/>
                                        </div>
                                    )}
                                </td>
                                <td className="input">
                                    <FieldTextBox
                                        name="claveSat"
                                        value={item.claveSat}
                                        errors={errors[`articulos[${index}]`]}
                                        disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                        onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                    />
                                </td>
                                <td className="input">
                                    <FieldTextBox
                                        name="descripcion"
                                        errors={errors[`articulos[${index}]`]}
                                        value={item.descripcion}
                                        disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                        onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                    />
                                </td>
                                <td className="input">
                                    <FieldTextBox
                                        name="cantidad"
                                        value={item.cantidad}
                                        errors={errors[`articulos[${index}]`]}
                                        disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                    />
                                </td>
                                <td className="input" style={{display: "flex"}}>
                                    <FieldDropDown
                                        name="unidadConClave"
                                        source="ArticuloUnidadConClave"
                                        value={item.unidadConClave}
                                        errors={errors[`articulos[${index}]`]}
                                        disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                        onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                    />
                                </td>
                                <td className="input">
                                    <FieldInputNumber
                                        name="peso"
                                        value={item.peso}
                                        fractionDigits={3}
                                        errors={errors[`articulos[${index}]`]}
                                        disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                        onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                    />
                                </td>
                                <td className="input">
                                    <FieldTextBox
                                        name="materialPeligroso"
                                        value={item.materialPeligroso}
                                        errors={errors[`articulos[${index}]`]}
                                        required={true}
                                        disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                        onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                    />
                                </td>
                                <td className="input">
                                    <FieldTextBox
                                        name="cveMaterialPeligroso"
                                        value={item.cveMaterialPeligroso}
                                        errors={errors[`articulos[${index}]`]}
                                        disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                        onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                    />
                                </td>
                                <td className="input">
                                    <FieldTextBox
                                        name="embalaje"
                                        value={item.embalaje}
                                        errors={errors[`articulos[${index}]`]}
                                        disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                        onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                    />
                                </td>
                                {viaje.internacional && (
                                    <>
                                        <td style={{width: "25px"}}>
                                            {item.fraccionValida ? (
                                                <div
                                                    className="text-success"
                                                    title="Ok"
                                                >
                                                    <IconLibrary iconName="FaCheck"/>
                                                </div>
                                            ) : (
                                                <div
                                                    className="text-danger"
                                                    title="Fraccion Arancelaria no existe en el catalogo del SAT"
                                                >
                                                    <IconLibrary iconName="RiErrorWarningLine"/>
                                                </div>
                                            )}
                                        </td>
                                        <td className="input">
                                            <FieldTextBox
                                                name="fraccionArancelaria"
                                                value={item.fraccionArancelaria}
                                                errors={errors[`articulos[${index}]`]}
                                                disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                                onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                            />
                                        </td>
                                        <td className="input">
                                            <FieldTextBox
                                                name="pedimento"
                                                value={item.pedimento}
                                                errors={errors[`articulos[${index}]`]}
                                                disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                                onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                            />
                                        </td>
                                        <td className="input">
                                            <FieldTextBox
                                                name="tipoMateria"
                                                value={item.tipoMateria}
                                                errors={errors[`articulos[${index}]`]}
                                                disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                                onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                            />
                                        </td>
                                        <td className="input">
                                            <FieldTextBox
                                                name="descripcionMateria"
                                                value={item.descripcionMateria}
                                                errors={errors[`articulos[${index}]`]}
                                                disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                                onChange={(value, pName) => actualizarArticulo(index, value, pName)}
                                            />
                                        </td>
                                        <td className="input">
                                            <FieldInputNumber
                                                name="valorMercancia"
                                                value={item.valorMercancia}
                                                fractionDigits={2}
                                                errors={errors[`articulos[${index}]`]}
                                                disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                                onChange={(value, pName) => actualizarArticulo(index, value, pName)}/>
                                        </td>
                                        <td className="input">
                                            <FieldTextBox
                                                name="moneda"
                                                value={item.moneda}
                                                errors={errors[`articulos[${index}]`]}
                                                disabled={viaje.deshabilitado || soloLectura || viaje.timbrado}
                                                onChange={(value, pName) => actualizarArticulo(index, value, pName)}/>
                                        </td>
                                    </>
                                )}
                                {!viaje.timbrado &&
                                    <td className="table-button py-0 px-1">
                                        <div className="d-flex" style={{float: "right"}}>
                                            {!soloLectura &&
                                                <ButtonIcon
                                                    title={"Eliminar"}
                                                    iconName={"RiDeleteBinLine"}
                                                    onClick={() => eliminarArticulo(index)}
                                                />
                                            }
                                        </div>

                                    </td>
                                }
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Articulos;
