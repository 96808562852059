/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FieldAutoComplete from "../../shared/controls/fieldAutoComplete";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import constantes from "../../catalogos/constantes";
import { TabPanel, TabView } from "primereact/tabview";
import CTPAT from "./secciones/ctpat";
import EPP from "./secciones/epp";
import formReducer from "../../shared/forms/formReducer";
import FormHandler from "../../shared/forms/formHandler";
import useApiPatios from "../../api/operaciones/useApiPatios";
import Inspeccion from "./secciones/inspeccion";
import imagenesUnidades from "./secciones/imagenesUnidades";
import InspeccionLlanta from "./secciones/inspeccionLlanta";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import alerts from "../../shared/alerts";
import controlPatioValidacionEsquema from "./controlPatioValidacionEsquema";
import { isTablet } from "react-device-detect";
import TableCheckListUnidad from "./checkListUnidades/tableCheckListUnidad";
import FieldInputNumber from "../../shared/controls/fieldInputNumber";
import { useEmpresa } from "../../layout/EmpresaProvider";
import FieldCamera from "../../shared/controls/fieldCamera";
import { Dialog } from "primereact/dialog";
import Compress from "compress.js";
import ImageViewer from "../../shared/componentes/imageViewer";
import useOfflineApi from "../../shared/offlineApi/useOfflineApi";
import { useLoadingDispatch } from "../../layout/show-loading-context";
import {useAuth} from "../../Seguridad/AuthProvider";
const ControlPatioDetalle = () => {
    const history = useNavigate();
    const {user} = useAuth();
    console.log(user)
    let { id, salida } = useParams();
    const esSalida = salida === "1";
    const [visibleModal, setVisibleModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const api = useApiPatios();
    const { empresaId, disable: disableEmpresa } = useEmpresa();
    const comprimir = new Compress();
    const [vistaPrevia, setVistaPrevia] = useState(false);
    const loadingDispatch = useLoadingDispatch();
    const usuarioId = user.username; 
    const { pushCall } = useOfflineApi();

    const defaultSolicitudServicio = () => ({
        entity: { id: 0, conCaja: true, esPermisionario: false },
    });

    const [{ entity, errors, submitted, ...state }, dispatch] = useReducer(formReducer, {}, defaultSolicitudServicio);
    const [datosPatios, setDatosPatio] = useState([]);
    useEffect(() => {
        async function cargar() {
            if (esSalida && id !== "0") {
                await api.actualizarLlantas(id);
            }
            const patios = await api.obtenerPatios({usuarioId });
            setDatosPatio(patios);
            const controlPatios = await api.obtener(id);
            dispatch({ type: "update", value: controlPatios });
          
        }

        cargar().then();
        disableEmpresa();
    }, []);

    useEffect(() => {
        if (submitted) formHandler.validate(entity);
    }, [entity]);

    async function actualizar(value, propertyName) {
        if (propertyName === "archivo") {
            let file = await resizeImageFn(value);
            const result = await api.guardarBitacora(entity.id, file);
            dispatch({ type: "update", value: true, name: "bitacora" });
            file.file = null;
            file.url = result.fileUrl;
            dispatch({ type: "update", value: file, name: propertyName });
            setVisibleModal(false);
        } else {
            dispatch({ type: "update", value: value, name: propertyName });

            if (value === true && propertyName === "esPermisionario") {
                actualizar(null, "unidad");
            }

            if (value === true && propertyName === "esExterno") {
                actualizar(null, "caja");
                dispatch({ type: "update", value: value, name: "esExterno" });
            }

            if (value !== null && propertyName === "nombreCaja") {
                var llantas = [];

                for (var i = 11; i <= 18; i++) {
                    var element = {
                        id: 0,
                        idCautin: "",
                        idCautinOriginal: i,
                        posicion: i,
                        requerido: false,
                    };
                    llantas.push(element);
                }

                actualizar(llantas, "llantasRemolque");
            }
        }
    }

    async function cambioUnidad(value, propertyName) {
        actualizar(value, propertyName);
        if (value?.puedeLlevarCaja === false && propertyName === "unidad") {
            actualizar(false, "conCaja");
        }
        const result = value != null ? await api.unidadAveriasyLLantas(value.id) : [];
        actualizar(result.averias, propertyName === "unidad" ? "averiasUnidad" : "averiasRemolque");
        actualizar(result.llantas, propertyName === "unidad" ? "llantasUnidad" : "llantasRemolque");

        if (propertyName === "unidad" && !esSalida) {
            const check = value != null ? await api.obtenerInformacion(value.id) : [];
            actualizar(check, "checkList");
        }

        if (esSalida && id === "0" && value != null) {
            const salidaId = await api.obtenerSalidaPorUnidad(value.id);
            await api.actualizarLlantas(salidaId);
            dispatch({ type: "update", value: await api.obtener(salidaId) });

            history("/controlDePatios/salida/" + 1 + "/" + salidaId);
        }
        if (propertyName === "unidad") {
            actualizar(value.placas, "placas");
        }
        if (propertyName === "caja") {
            actualizar(value.placasRem, "placasRem");
        }
    }

    const formHandler = new FormHandler({
        validationSchema: controlPatioValidacionEsquema.salida,
        api: api,
        dispatch,
    });

    function tabLlantasDesactivadas() {
        return (
            entity.unidad == null && entity.nombreUnidad === null

            // (entity.unidad == null && entity.caja === null && entity.nombreCaja == null && entity.nombreUnidad === null)
        );
    }

    function tabAccesoriosDesactivadas() {
        return entity.checkList == null || entity.checkList.length === 0 || entity.checkList[0].checkList.length === 0;
    }

    const guardar = async () => {
        if (esSalida) {
            if (entity?.bitacoras > 0) {
                if (!entity?.bitacora) {
                    await alerts.aviso("Es necesario verificar si el operador lleva su bitacora viaje.");
                    return;
                }
            }
        }

        await formHandler.validateSubmit(entity);

        if (formHandler.valdation.result) {
            const apiUrl = esSalida
                ? entity.id > 0
                    ? `/operaciones/controlPatios/${entity.id}`
                    : "/operaciones/controlPatios/salida"
                : "/operaciones/controlPatios/acceso";
            pushCall(entity, apiUrl);
            loadingDispatch({ type: "toast", message: "La solicitud fué enviada correctamente" });
            history("/controlDePatios");
        }
    };

    function unidadDesactivada(averias, llantas) {
        return (
            esSalida ||
            !entity.editable ||
            averias?.findIndex((e) => e.editable === true) >= 0 ||
            llantas?.findIndex((e) => e.idCautin.length > 0 && e.idCautinOriginal.length) >= 0
        );
    }

    function unidadBusqueda(averias, llantas) {
        if (esSalida && id === "0") return false;
        else {
            return unidadDesactivada(averias, llantas);
        }
    }

    async function back() {
        if (!state.isDirty || (await alerts.preguntarSiDeseaSalirSinGuardar())) {
            history(-1);
        }
    }

    function errorCtpat() {
        return (
            errors?.inspeccionUnidad ||
            errors?.inspeccionRemolque ||
            errors?.medidasExternas ||
            errors?.medidasInternas ||
            errors?.tamanioRemolque
        );
    }

    function errorLlantas() {
        return errors?.llantasUnidad || errors?.llantasRemolque;
    }

    function errorCheckList() {
        return errors?.checkList;
    }

    async function resizeImageFn(file) {
        var imageData = file.file.toString();
        var byteCharacters = atob(imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, ""));
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
            type: "image/png",
        });

        const resizedImage = await comprimir.compress([blob], {
            size: 2, // the max size in MB, defaults to 2MB
            quality: 0.6, // the quality of the image, max is 1,
            maxWidth: 500, // the max width of the output image, defaults to 1920px
            maxHeight: 300, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
        });
        const img = resizedImage[0];

        var data = "data:image/png;base64," + img.data.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");

        return {
            file: data,
            url: data,
            isEmpty: false,
            nombre: "foto.jpg",
            isBase64: true,
            extension: "png",
        };
    }

    function eliminarfoto() {
        dispatch({ type: "update", value: false, name: "bitacora" });
        dispatch({ type: "update", value: null, name: "archivo" });
    }

    return (
        <>
            <div className={`form-filtro form-row`}>
                {entity.nombreUnidad != null && esSalida && !entity.editable ? (
                    <FieldTextBox label="No. Económico" colMd={2} value={entity.nombreUnidad} disabled />
                    
                    
                ) : (
                    <>
                        {entity.esPermisionario === false ? (
                              <>
                                <FieldAutoComplete
                                    name="unidad"
                                    source="unidades"
                                    label="No. Económico"
                                    required
                                    disabled={unidadBusqueda(entity.averiasUnidad, entity.llantasUnidad)}
                                    params={{ todasLasEmpresas: true, id: constantes.tiposEquipo.motriz }}
                                    colMd={2}
                                    value={entity.unidad}
                                    onChange={cambioUnidad}
                                    errors={errors}
                                />
                                <FieldTextBox
                                    disabled={true}
                                    label="*Placas"
                                    errors={errors}
                                    name="placas"
                                    onChange={actualizar}
                                    colMd={2}
                                    value={entity.placas}

                                />
                                
                                <FieldDropDown
                                    name="patioUbicacion"
                                    label="Ubicacion"
                                    colMd={2}
                                    filter={true} 
                                    options={datosPatios.patioUbicacion}                                   
                                    required
                                    errors={errors}
                                    value={entity.patioUbicacion}
                                    onChange={actualizar}
                                />
                                <div style={{marginRight: "-25px"}}
                                        className={`form-group col-md-${isTablet ? "3" : "2"} col-sm-4`}>
                                    <label>Verificación Placas Unidad</label>
                                    <FieldCheckBox
                                        name="verificacionPlacasUnidad"
                                        isRadioButton={true}
                                        yesOption={"Si"}
                                        noOption={"No"}
                                        value={entity.verificacionPlacasUnidad}
                                        onChange={actualizar}
                                        errors={errors}
                                    />
                                </div>
                            </>
                        ) : (
                            <FieldTextBox
                                disabled={!entity.editable}
                                label="*No. Económico"
                                errors={errors}
                                name="nombreUnidad"
                                onChange={actualizar}
                                colMd={2}
                                value={entity.nombreUnidad}
                            />
                        )}

                        <FieldCheckBox
                            colMd={1}
                            disabled={!entity.editable || esSalida}
                            label="Permisionario"
                            name="esPermisionario"
                            value={entity.esPermisionario}
                            onChange={actualizar}
                        />
                    </>
                )}

                <div style={{ marginRight: "-25px" }} className={`form-group col-md-${isTablet ? "3" : "2"} col-sm-6`}>
                    <label />
                    <FieldCheckBox
                        name="conCaja"
                        isRadioButton={true}
                        disabled={!entity.editable || esSalida || entity.unidad?.puedeLlevarCaja === false}
                        yesOption={"Con remolque"}
                        noOption={"Sin remolque"}
                        value={entity.conCaja}
                        onChange={actualizar}
                    />
                </div>
                {entity.conCaja && (
                    <>
                        {entity.nombreCaja != null && esSalida && !entity.editable ? (
                            <FieldTextBox label="No. Remolque" colMd={2} value={entity.nombreCaja} disabled />
                        ) : (
                            <>
                                {entity.esExterno === false ? (
                                    <>
                                    <FieldAutoComplete
                                        name="caja"
                                        source="unidades"
                                        label="No. Remolque"
                                        required
                                        disabled={unidadDesactivada(entity.averiasRemolque, entity.llantasRemolque)}
                                        params={{
                                            todasLasEmpresas: true,
                                            id: constantes.tiposEquipo.arrastre,
                                        }}
                                        colMd={2}
                                        value={entity.caja}
                                        onChange={cambioUnidad}
                                        errors={errors}
                                    />
                                    <FieldTextBox
                                    disabled={true}
                                    label="*Placas"
                                    errors={errors}
                                    name="placasRem"
                                    onChange={actualizar}
                                    colMd={2}
                                    value={entity.placasRem}
                                />
                                </>
                                ) : (
                                    <FieldTextBox
                                        disabled={!entity.editable || esSalida}
                                        label="*No. Remolque"
                                        errors={errors}
                                        name="nombreCaja"
                                        onChange={actualizar}
                                        colMd={2}
                                        value={entity.nombreCaja}
                                    />
                                )}

                                <FieldCheckBox
                                    disabled={!entity.editable || esSalida}
                                    colMd={1}
                                    label="Externo"
                                    name="esExterno"
                                    value={entity.esExterno}
                                    onChange={actualizar}
                                />
                            </>
                        )}
                    </>
                )}
                   {
                        <div style={{marginRight: "-25px"}}
                            className={`form-group col-md-${isTablet ? "2" : "2"} col-sm-4`}>
                        <label>Verificación Placas Remolque</label>
                        <FieldCheckBox
                            name="verificacionPlacas"
                            isRadioButton={true}
                            yesOption={"Si"}
                            noOption={"No"}
                           
                            value={entity.verificacionPlacas}
                            onChange={actualizar}
                            errors={errors}
                        />
                    </div>
                }

                {(entity.conCaja || entity.unidad?.puedeLlevarCaja === false) && (
                    <>
                        <FieldTextBox
                            name="selloFiscal"
                            label="Sello Fiscal"
                            colMd={2}
                            required
                            disabled={!entity.editable || esSalida}
                            value={entity.selloFiscal}
                            onChange={actualizar}
                        />
                    </>
                )}

                <div className="field-iconos btn-group">
                    <ButtonIcon title="Regresar" iconName={"BiArrowBack"} onClick={back} />

                    {entity.editable && (
                        <ButtonIcon title="Guardar" className="action" iconName={"FaCheckCircle"} onClick={guardar} />
                    )}
                </div>
            </div>

            <div className={`form-row`}>
                {(entity.conCaja || entity.unidad?.puedeLlevarCaja === false) && (
                    <>
                        <FieldTextBox
                            name="selloABC"
                            label="Sello ABC"
                            colMd={2}
                            required
                            disabled={!entity.editable}
                            value={entity.selloABC}
                            onChange={actualizar}
                        />
                    </>
                )}

                <FieldTextBox
                    maxLength={600}
                    disabled={!entity.editable}
                    label="Comentarios"
                    name="comentarios"
                    onChange={actualizar}
                    colMd={6}
                    value={entity.comentarios}
                />
                <FieldInputNumber
                    label="Odometro"
                    name="odometro"
                    value={entity.odometro}
                    disabled={!entity.editable}
                    colMd={2}
                    errors={errors}
                    onChange={actualizar}
                />
                {entity?.bitacoras > 0 && (
                    <>
                        <FieldCheckBox
                            colMd={1}
                            disabled={true}
                            label={"Bitacora de Viaje"}
                            name={"bitacora"}
                            value={entity?.bitacora}
                            onChange={actualizar}
                        />
                        {entity.archivo === null ? (
                            <div
                                className={`text-center ${errors != null ? "error" : ""}`}
                                onClick={() => setVisibleModal(true)}
                            >
                                <img width={"38%"} src={`../../../img/camera.png`} alt="camera" />
                                <div>Agregar Foto</div>
                            </div>
                        ) : (
                            <>
                                <div className="d-flex" style={{ alignItems: "center" }}>
                                    {entity.editable && (
                                        <ButtonIcon
                                            title={"Eliminar"}
                                            onClick={eliminarfoto}
                                            iconName={"RiDeleteBinLine"}
                                        />
                                    )}
                                    <ButtonIcon
                                        title={"Visualizar"}
                                        onClick={() => setVistaPrevia(true)}
                                        iconName="GrFormView"
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>

            <div className={`form-row`}>
                {entity.operadorExterno ? (
                    <FieldTextBox
                        name="nombreOperador"
                        label="Nombre Operador"
                        maxLength={800}
                        colMd={3}
                        required
                        disabled={!entity.editable || esSalida}
                        upperCase
                        value={entity.nombreOperador}
                        onChange={actualizar}
                        errors={errors}
                    />
                ) : (
                    <FieldDropDown
                        name="operador"
                        disabled={!entity.editable || esSalida}
                        source="empleados"
                        params={{todasLasEmpresas: true, puestoIds: [constantes.puestos.operador]}}
                        filter={true}
                        required
                        label="Operador"
                        colMd={2}
                        colMob={4}
                        value={entity.operador}
                        onChange={actualizar}
                        errors={errors}
                    />
                )}

                <FieldCheckBox
                    disabled={!entity.editable || esSalida}
                    colMd={1}
                    label="Operador Externo"
                    name="operadorExterno"
                    value={entity.operadorExterno}
                    onChange={actualizar}
                />

                <FieldDropDown
                    name="vigilante"
                    disabled={!entity.editable}
                    source="empleados"
                    params={{todasLasEmpresas: true, puestoIds: [constantes.puestos.guardiaSeguridad]}}
                    filter={true}
                    required
                    label="Vigilante"
                    colMd={2}
                    colMob={4}
                    value={entity.vigilante}
                    onChange={actualizar}
                    errors={errors}
                />
                {esSalida ? (
                    <>
                        <FieldTextBox
                            name="cliente"
                            label="Cliente"
                            colMd={2}
                            value={entity.cliente?.nombre}
                            disabled
                        />
                        <FieldTextBox name="Planta" label="Planta" colMd={2} value={entity.plantaDestino} disabled/>
                        <FieldTextBox
                            name="Solicitante"
                            label="Solicitante"
                            colMd={2}
                            value={entity.nombreSolicitante}
                            disabled
                        />
                    </>
                ) : (
                    <FieldTextBox
                        name="origen"
                        label="Origen"
                        colMd={2}
                        required
                        value={entity.origen}
                        onChange={actualizar}
                        disabled={!entity.editable}
                        errors={errors}
                    />
                )}
                {entity.conCaja && !entity.esExterno &&
                <div style={{marginRight: "-25px"}}
                     className={`form-group col-md-${isTablet ? "3" : "2"} col-sm-6`}>
                        <label>Caja</label>
                        <FieldCheckBox
                            name="unidadCargada"
                            isRadioButton={true}
                            yesOption={"Cargada"}
                            noOption={"Vacía"}
                            value={entity.unidadCargada}
                            onChange={actualizar}
                            errors={errors}
                        />
                    </div>
                }
            </div>
         
            <fieldset disabled={!entity.editable}>
                <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                    className="main-tab-view"
                >
                    <TabPanel header="C-TPAT" rightIcon={errorCtpat() ? "pi pi-exclamation-circle" : ""}>
                        <CTPAT actualizar={actualizar} entity={entity} errors={errors}/>
                    </TabPanel>
                    <TabPanel header="EPP" rightIcon={errorCtpat() ? "pi pi-exclamation-circle" : ""}>
                        <EPP actualizar={actualizar} entity={entity} errors={errors}/>
                    </TabPanel>
                    <TabPanel header="UNIDAD" disabled={entity.unidad == null}>
                        <Inspeccion
                            value={entity.averiasUnidad}
                            name={"averiasUnidad"}
                            onChange={actualizar}
                            imagenes={imagenesUnidades.motriz}
                            tipoUnidadId={entity.unidad?.tipoUnidadId}
                            disabled={!entity.editable}
                        />
                    </TabPanel>
                    <TabPanel
                        header="REMOLQUE"
                        headerStyle={{ display: entity.conCaja ? "" : "none" }}
                        disabled={entity.caja == null && !entity.esExterno}
                    >
                        <Inspeccion
                            value={entity.averiasRemolque}
                            name={"averiasRemolque"}
                            onChange={actualizar}
                            imagenes={imagenesUnidades.arrastre}
                            tipoUnidadId={entity.caja?.tipoUnidadId}
                            disabled={!entity.editable}
                            entity={entity}
                        />
                    </TabPanel>
                    <TabPanel
                        header="LLANTAS"
                        disabled={tabLlantasDesactivadas()}
                        rightIcon={errorLlantas() ? "pi pi-exclamation-circle" : ""}
                    >
                        <InspeccionLlanta
                            editable={entity.editable}
                            entity={entity}
                            actualizar={actualizar}
                            errors={errors}
                            tipoUnidadId={entity.unidad?.tipoUnidadId}
                        />
                    </TabPanel>
                    <TabPanel
                        rightIcon={errorCheckList() ? "pi pi-exclamation-circle" : ""}
                        disabled={tabAccesoriosDesactivadas()}
                        header="ACCESORIOS"
                    >
                        <TableCheckListUnidad
                            errors={errors}
                            ocultarHeader={true}
                            disabled={(!entity.editable && !esSalida) || (!entity.editable && esSalida)}
                            onChange={actualizar}
                            checklist={entity.checkList}
                        />
                    </TabPanel>
                </TabView>
            </fieldset>
            <Dialog header="Tomar Fotografia" visible={visibleModal} onHide={() => setVisibleModal(false)}>
                <FieldCamera value={entity?.archivo} name="archivo" useCamera={visibleModal} onChange={actualizar} />
            </Dialog>
            <ImageViewer
                header={"Bitacora"}
                name="foto"
                value={entity?.archivo}
                onChange={null}
                visible={vistaPrevia}
                hide={() => setVistaPrevia(false)}
                urlApi={`archivos/vistaPrevia/${empresaId}?url=${entity?.archivo?.url}`}
            />
        </>
    );
};

export default ControlPatioDetalle;
