import * as yup from "yup";

const salida = yup.object().shape({
    operadorExterno: yup.boolean(),
    operador: yup.mixed().when(['operadorExterno', 'id'], {
    is: (operadorExterno, id) => !operadorExterno && id === 0,
        then: yup.mixed().required(),
    }),

    vigilante: yup.mixed().required(),
    patioUbicacion: yup.mixed().required(),
    odometro: yup.mixed().required(),
    verificacionPlacasUnidad: yup.mixed().when('esPermisionario', {
        is: true, 
        then: yup.mixed().notRequired(),
        otherwise: yup.mixed().required(''), 
      }),
    
      verificacionPlacas: yup.mixed().when('esPermisionario', {
        is: true,  
        then: yup.mixed().notRequired(), 
        otherwise: yup.mixed().required(''),
      }),
    
    nombreUnidad: yup.mixed()
        .when("esPermisionario", {
            is: true,
            then: yup.mixed().required()
        }),
    nombreCaja: yup.mixed().when(["esExterno", "conCaja"], {
        is: (a, b) => a === true && b === true,
        then: yup.mixed().required()
    }),
    unidad: yup.mixed()
        .when(['esPermisionario', 'id'], {
            is: (a, b) => a === false && b === 0,
            then: yup.mixed().required()
        }),
    caja: yup.mixed().when(['conCaja', 'id', 'esExterno'], {
        is: (a, b, c) => a && b === 0 && c === false,
        then: yup.mixed().required()
    }),
    origen: yup.string()
        .when("id", {
            is: 0,
            then: yup.string().required()
        }),
    tamanioRemolque: yup.mixed().when(['conCaja', 'id'], {
        is: (a, b) => a && b === 0,
        then: yup.number().required().min(1)
    }),
    medidasInternas: yup.mixed().when(['conCaja', 'id'], {
        is: (a, b) => a && b === 0,
        then: yup.object({
            alto: yup.number().required().min(1),
            ancho: yup.number().required().min(1),
            largo: yup.number().required().min(1)
        }),
    }),
    medidasExternas: yup.mixed().when(['conCaja', 'id'], {
        is: (a, b) => a && b === 0,
        then: yup.object({
            alto: yup.number().required().min(1),
            ancho: yup.number().required().min(1),
            largo: yup.number().required().min(1)
        }),
    }),
    inspeccionUnidad: yup.array()
        .of(
            yup.object().shape({
                necesitaServicio: yup.number().min(0).required()
            })
        ),
    inspeccionRemolque: yup.mixed().when(['conCaja', 'id'], {
        is: (a, b) => a && b === 0,
        then: yup.array()
            .of(
                yup.object().shape({
                    necesitaServicio: yup.mixed().required()
                })
            ),
    }),

    llantasUnidad: yup.array()
        .of(
            yup.object().shape({
                idCautin: yup.mixed().when(["requerido", "esPermisionario"], {
                    is: (a, b) => a === true && b === false,
                    then: yup.string().required()
                }),
                marcaLlanta: yup.mixed().when(["requerido", "esPermisionario"], {
                    is: (a, b) => a === true && b === false,
                    then: yup.string().required()
                }),

            })
        ),
    llantasRemolque: yup.array()
        .of(
            yup.object().shape({
                idCautin: yup.mixed().required()

            }),

        ),
    checkList: yup.array()
        .of(
            yup.object().shape({
                checkList: yup.array().of(
                    yup.object().shape({
                            comentarios: yup.mixed().when(['esIncidencia', 'puntoInspeccion.id'], {
                                is: (a, b) => a,
                                then: yup.string().required()
                            }),
                            fotoActual: yup.mixed().when(['puntoInspeccion.id'], {
                                is: (a) => true,
                                then: yup.mixed().required()
                            })
                        }
                    ),
                ),


            })

        ),
});

const checkList = yup.object().shape({
    checkList: yup.array()
        .of(
            yup.object().shape({
                checkList: yup.array().of(
                    yup.object().shape({
                        comentarios: yup.mixed().when(['esIncidencia', 'puntoInspeccion.id'], {
                            is: (a, b) => a,
                            then: yup.string().required()
                        }),
                        fotoActual: yup.mixed().when(['puntoInspeccion.id'], {
                            is: (a) => true,
                            then: yup.mixed().required()
                        })
                    }
                    ),
                ),


            })

        ),
});

const salidasValidacionEsquema = {
    salida,
    checkList
}

export default salidasValidacionEsquema;
